
import { Component, Vue } from 'vue-property-decorator';
import Sticky from '@/components/Sticky.vue';
import Page from '@/components/Page.vue'

@Component({
  components: {
    Sticky,
    Page,
  }
})
export default class ServicesView extends Vue {
  rates = [
    { price: 40, key: 'particular' },
    { price: 45, key: 'group' },
    { price: 50, key: 'business' },
  ]
}
