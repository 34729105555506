
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Page extends Vue {
  @Prop({ type: String, default: null }) color!: string | null;
  @Prop({ type: String, default: null }) video!: string | null;
  @Prop({ type: Number, default: null }) overlay!: number | null;
  @Prop({ type: Array, default: null }) images!: Array<string> | null;
  @Prop({ type: Number, default: null }) forceFlatToolbarOffset!: number | null;

  videoLoaded = false
  carouselIdx = 0

  get carouselHeight(): number | string {
    return this.$vuetify.breakpoint.mdAndUp
      ? '100vh'
      : '33vh';
  }

  onScroll(e: any) {
    const originalValue = this.$store.state.forceFlatToolbar;
    let newValue = false;
    if (this.forceFlatToolbarOffset !== null && this.forceFlatToolbarOffset <= window.scrollY) {
      newValue = true;
    }
    if (newValue !== originalValue) {
      this.$store.commit('forceFlatToolbar', newValue);
    }
  }

  created() {
    this.$store.commit('background', this.color);
    this.$vuetify.goTo('#app', {
      duration: 0
    });
  }
}
