
import { Component, Vue, Watch } from 'vue-property-decorator';
import VideoPlayer from '@/components/VideoPlayer.vue';
import Sticky from '@/components/Sticky.vue';
import Page from '@/components/Page.vue'
import Papa from 'papaparse';

interface ITestimonial {
  expand: boolean,
  name: string,
  comment: string,
  attitude: number,
  clarity: number,
  knowledge: number,
  flexibility: number,
  material: number,
  method: number,
  organization: number,
  average: number,
  company: string | null,
}

@Component({
  components: {
    Sticky,
    Page,
    VideoPlayer,
  }
})
export default class TestimonialsView extends Vue {

  limit = 300

  @Watch('comments')
  onCommentChange() {
    this.$nextTick(this.redrawMasonry);
  }

  get videos(): any[] {
    return this.$jms('testimonials.videos');
  }

  get comments(): any[] {
    return this.$jms('testimonials.items').reverse();
  }

  onShowMoreLess(testimonial: ITestimonial): void {
    testimonial.expand = !testimonial.expand;
    this.$nextTick(this.redrawMasonry);
  }

  redrawMasonry(): void {
    // @ts-ignore
    this.$redrawVueMasonry('testimonials');
  }
}
