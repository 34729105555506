const links: Array<{
  key: string,
  icon: string | null,
}> = [
  { key: 'home', icon: 'mdi-presentation' },
  { key: 'services', icon: 'mdi-teach' },
  { key: 'testimonials', icon: 'mdi-comment-check-outline' },
  { key: 'faq', icon: 'mdi-frequently-asked-questions' },
  { key: 'contact', icon: 'mdi-email-outline' },
]

const calendlyLink = 'https://calendly.com/try-me/30min';

const availableLanguages = [
  { text: 'Français (Canada)', value: 'fr-CA', },
  { text: 'English (Canada)', value: 'en-CA', },
  { text: 'Español (Perú)', value: 'es-PE', },
]

export {
  links,
  availableLanguages,
  calendlyLink,
}
