
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';

@Component
export default class VideoPlayer extends Vue {
  @Ref() video!: HTMLVideoElement;

  @Prop({ type: String, default: null }) src!: string;
  @Prop({ type: Number, default: 16/9 }) aspectRatio!: number;

  videoLoaded = false

  get style(): { [key: string]: string | number } {
    return {
      opacity: this.videoLoaded ? 1 : 0,
      maxWidth: '100%',
      float: 'left',
    }
  }
}
