
import { Vue, Component, Watch } from 'vue-property-decorator';
import Sidebar from '@/components/Sidebar.vue';
import { availableLanguages, links } from '@/global';
import { calendlyLink } from '@/global'

@Component({
  components: {
    Sidebar,
  }
})
export default class HomeView extends Vue {
  drawer = false
  links = links
  availableLanguages = availableLanguages
  calendlyLink = calendlyLink

  @Watch('$i18n.locale')
  onI18nLocaleChange(locale: string) {
    localStorage.setItem('locale', locale);
  }

  get flatToolbar(): boolean {
    return !this.$store.state.forceFlatToolbar && this.$vuetify.breakpoint.lgAndUp;
  }

  get socialLinks(): any {
    return [
      { href: this.$jms('settings.linkedin'), icon: 'mdi-linkedin', name: 'LinkedIn' },
    ];
  }

  onAppBarNavClick() {
    this.drawer = !this.drawer;
  }
}
