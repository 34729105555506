import { render, staticRenderFns } from "./ServicesView.vue?vue&type=template&id=aab23cac&scoped=true&"
import script from "./ServicesView.vue?vue&type=script&lang=ts&"
export * from "./ServicesView.vue?vue&type=script&lang=ts&"
import style0 from "./ServicesView.vue?vue&type=style&index=0&id=aab23cac&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aab23cac",
  null
  
)

export default component.exports